import {Component, Provide, Vue, Watch,} from 'vue-property-decorator';
import {DeleteAddressApi} from "@/network/modules/mine";
import {AddAddressReq, DeleteAddressReq} from "@/interface/res/mine";
import {mapGetters} from "vuex";
import common from '../Common/Index.vue'
import AddAddress from "@/components/AddAddress.vue";

@Component({
  components: {
    common,
    AddAddress
  },
  computed: {
    ...mapGetters(["addressList"])
  }
})
export default class Address extends Vue {
  @Provide() list:Array<any> = []
  @Provide() listTotal:number = 0

  @Provide() loading:boolean = false

  @Provide() addressId:number = -1
  @Provide() deleteShow:boolean = false
  @Provide() deleteLoading:boolean = false

  @Provide() addressLists:Array<any> = []
  @Provide() isAddressMoreClick:boolean = false
  @Provide() isAddressMore:boolean = false
  @Provide() isAddressIndex:number = 0
  @Provide() addressType:number = 0
  @Provide() addressShow:boolean = false
  @Provide() addressData:AddAddressReq = {}

  @Watch('addressList',{immediate:true, deep:true})
  private iListChange(newVal:Array<any>):void {
    const _this:any = this;
    _this.list = newVal
    _this.listTotal = newVal.length
  }

  created(){
    const _this:any = this;

  }

  /**
   * @Author HS
   * @Date 2021/7/30 10:12 上午
   * @Description: 打开地址弹窗
   * @Params: { number ： index - false } [下标：false-新增 true-下标]
   * @Return: null
   */
  addressShowFn(index:number){
    const _this:any = this;
    if(index == undefined){
      _this.addressType = 0
    }
    else{
      _this.addressType = 1
      _this.addressData = {..._this.list[index]}
    }
    _this.addressShow = true
  }

  /**
   * @Author HS
   * @Date 2021/7/19 12:00 下午
   * @Description: 删除地址
   * @Params: { number ： id - true } [id]
   * @Return: null
  */
  async deleteFn(){
    const _this:any = this;
    _this.deleteLoading = true
    const params:DeleteAddressReq = {
      addressId:_this.addressId
    }
    const res = await DeleteAddressApi(params)
    let a = setTimeout(()=>{_this.deleteLoading = false;clearTimeout(a)},500)
    const { code, msg } = res
    if(code != 200 ){
      _this.$message.error(msg);
      return
    }
    const listIndex = _this.list.findIndex(((item:any)=> item.addressId == _this.addressId))
    _this.$store.commit('REMOVE_ADDRESS_LIST_ITEM',listIndex)
    _this.deleteShow = false
  }
  /**
   * @Author HS
   * @Date 2021/7/19 12:00 下午
   * @Description: 删除地址
   * @Params: { number ： id - true } [id]
   * @Return: null
   */
  deleteShowFn(id:number){
    const _this:any = this;
    _this.addressId = id
    _this.deleteShow = true
  }


  /**
   * @Author HS
   * @Date 2021/7/19 10:55 上午
   * @Description: 默认地址
   * @Params: { number ： val - true } [值]
   * @Return: null
  */
  changeFn(){
    const _this:any = this;
    _this.form.defaultStatus == 0 ? _this.form.defaultStatus = 1 : _this.form.defaultStatus = 0
  }

}
